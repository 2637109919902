
import axios from 'axios';
import * as xlsx from 'xlsx'
import AppSettings from '../logic/AppSettings.js';

const SERVER_SETTING_URL = `${AppSettings.BACKEND_URL}/settings`;
const SERVER_COMPANY_URL = `${AppSettings.BACKEND_URL}/companies`;

/**
 * Excel manipulation and blob
 * 
 *      xlsx: https://docs.sheetjs.com/docs/solutions/output
 *      blob: https://stackoverflow.com/questions/46909260/reading-excel-file-in-reactjs
 * 
 */
const ImportExportService = () => {

    /**
     * Initialize the skillspot database with a json string
     */
    const importDatabase = async (data) => {
        await axios.post(`${SERVER_SETTING_URL}/import`, { data });
    }

    /**
     * Return skillspot database content in json format (string)
     */
    const exportDatabase = async () => {

        // Get data from server
        const data = (await axios.get(`${SERVER_SETTING_URL}/export`)).data.payload;

        // Convert the string into json
        return JSON.stringify(data, null, 2);
    }

    /**
     * Initialize a skillspot company with an excel buffer
     */
    const importCompany = async (company, excel) => {

        const workbook = xlsx.read(excel);
        
        const data = {};
        let worksheet = null;
        
        // Extract users data
        worksheet = workbook.Sheets['Users'];
        const users = xlsx.utils.sheet_to_json(worksheet, { raw: false, blankrows: false });
        data.users = users;

        // Extract workplaces data
        worksheet = workbook.Sheets['Workplaces'];
        const workplaces = xlsx.utils.sheet_to_json(worksheet, { raw: false, blankrows: false });
        data.workplaces = workplaces;

        // Extract positionTypes data
        worksheet = workbook.Sheets['PositionTypes'];
        const positiontypes = xlsx.utils.sheet_to_json(worksheet, { raw: false, blankrows: false });
        data.positiontypes = positiontypes;

        // Adjust excel dates
        for(const user of data.users) { 
            if (user.birthdate) user.birthdate = new Date(user.birthdate); 
            if (user.entrydate) user.entrydate = new Date(user.entrydate); 
        }

        // Push data into the backend
        await axios.post(`${SERVER_COMPANY_URL}/${company.id}/import`, data);        
    }

    /**
     * Return company data in excel format (in a buffer)
     */
    const exportCompany = async (company) => {

        // Get data from server
        const data = (await axios.get(`${SERVER_COMPANY_URL}/${company.id}/export`)).data.payload;

        // Create an xls file and write result into a buffer
        const workbook = xlsx.utils.book_new();

        // Adjust excel dates
        for(const user of data.users) { 
            if (user.birthdate) user.birthdate = new Date(user.birthdate); 
            if (user.entrydate) user.entrydate = new Date(user.entrydate); 
        }

        // Create users sheet
        const wsUsers = xlsx.utils.json_to_sheet(data.users);
        xlsx.utils.book_append_sheet(workbook, wsUsers, "Users");
        
        // Create workplaces sheet
        const wsWorkplaces = xlsx.utils.json_to_sheet(data.workplaces);
        xlsx.utils.book_append_sheet(workbook, wsWorkplaces, "Workplaces");

        // Create positiontypes sheet
        const wsPositionTypes = xlsx.utils.json_to_sheet(data.positiontypes);
        xlsx.utils.book_append_sheet(workbook, wsPositionTypes, "PositionTypes");

        return xlsx.write(workbook, { bookType: 'xlsx', type: 'buffer' });        
    }
    
    return {
        importDatabase,
        exportDatabase,
        importCompany,
        exportCompany
    }
}

export default ImportExportService();