
import axios from "axios";
import AppSettings from '../logic/AppSettings.js';

const BACKEND_URL = `${AppSettings.BACKEND_URL}/diplomatypes`;

const DiplomaTypeService = () => {

    const getDiplomaTypes = async (filter) => {

        let query = filter ? '?' : '';

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${BACKEND_URL}${query}`)).data.payload;
    }

    const getDiplomaType = async (id) => {
        return (await axios.get(`${BACKEND_URL}/${id}`)).data.payload;
    }

    const create = async () => {
        return (await axios.get(`${BACKEND_URL}/create`)).data.payload;
    }

    const isDeletable = async (diplomaType) => {
        return (await axios.get(`${BACKEND_URL}/${diplomaType.id}/deletable`)).data.payload;
    }

    const save = async (diplomaType) => {

        return diplomaType.id
            ? (await axios.put(`${BACKEND_URL}/${diplomaType.id}`, diplomaType)).data.payload
            : (await axios.post(`${BACKEND_URL}`, diplomaType)).data.payload;
    }

    const remove = async (diplomaType) => {
        await axios.delete(`${BACKEND_URL}/${diplomaType.id}`);
    }

    return {
        getDiplomaTypes,
        getDiplomaType,
        create,
        isDeletable,
        save,
        remove
    }
}

export default DiplomaTypeService();