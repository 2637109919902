
import { useTranslation } from 'react-i18next';
import ButtonBar from './ButtonBar.js';

const MindsetView = ({ mindset, disabled, onChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { step, questions, responses } = mindset;

    /**
     * Event handlers
     */

    const handleClick = (selection) => {
        responses[step] = selection;
        onChange && onChange({ step, questions, responses });
    }

    const handleNext = () => {
        onChange && onChange({ step: step + 1, questions, responses });
    }

    return (
        <div className='container p-0 m-0'>
            <div className='d-flex justify-content-center align-items-center border rounded' style={{ height: '45vh' }}>

                {
                    (disabled || step >= questions.length) &&
                    <h5 className='text-muted'>{t('invitations.mindsetcompleted')}</h5>
                }

                {
                    !disabled && step < questions.length &&
                    <div className='d-flex flex-column text-center p-5'>

                        <h5 className='text-muted'>
                            {t('invitations.question')} {step + 1} / {questions.length}
                        </h5>

                        <h5 className='text-center px-5'>
                            { questions[step].label }
                        </h5>

                        <ButtonBar className='py-4' question={questions[step]} response={responses[step]} onClick={(selection) => handleClick(selection)} />

                        <div>
                            <button className='btn btn-primary my-3 px-4' disabled={step > questions.length - 1 || responses[step] === 0} onClick={handleNext}>
                                {t('button.next')}
                            </button>
                        </div>

                    </div>
                }

            </div>
        </div>
    )
}

export default MindsetView
