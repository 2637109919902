
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useSectorController from '../../../controllers/useSectorController.js';

import AppSettings from '../../../logic/AppSettings.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import SectorDetail from './SectorDetail.js';
import SectorDelete from './SectorDelete.js';
import Paginator from '../../../components/filter/Paginator.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Sectors = () => {

    /**
     * Page state
    */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppSettings.PAGE_LIMIT, sort: [] });
    const [sector, setSector] = useState({});
    const [sectors, setSectors] = useState([]);

    const sectorController = useSectorController();

    const columns = {
        code: { property: 'code', display: 'sectors.code' },
        name: { property: 'name', display: 'sectors.name' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await sectorController.getSectors(filter);
        setSectors(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [])

    // eslint-disable-next-line 
    useEffect(() => { sectorController.retranslate(sectors); setSectors([...sectors]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const sector = await sectorController.create();
        setSector(sector);
        ReactUtil.show('detail');
    }
    
    const handleDetail = async (id) => {
        const sector = await sectorController.getSector(id);
        setSector(sector);
        ReactUtil.show('detail');
    }

    const handleSave = async (sector) => {
        await sectorController.save(sector);
        reload(filter);
    }

    const handleDelete = async (sector) => {
        await sectorController.remove(sector);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!sectorController.loaded} />
            <SectorDetail id='detail' sector={sector} onSave={handleSave} />
            <SectorDelete id='delete' sector={sector} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.sectors')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.code} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sectors.map((sector) => (
                                <tr key={sector.id} className='align-middle'>

                                    <td>{sector.code}</td>
                                    <td>{sector.name}</td>

                                    <td className='text-center'>
                                        <button className='btn btn-light' onClick={() => handleDetail(sector.id)}>
                                            <img src={edit_img} alt='edit' />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Sectors
