
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useCompanyController from '../../../controllers/useCompanyController.js';

import AppSettings from '../../../logic/AppSettings.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';
import CompanyDetail from './CompanyDetail.js';
import CompanyDelete from './CompanyDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Companies = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppSettings.PAGE_LIMIT, sort: [] });
    const [company, setCompany] = useState({});
    const [companies, setCompanies] = useState([]);

    const companyController = useCompanyController();

    const columns = {
        name: { property: 'name', display: 'companies.name' },
        street: { property: 'street', display: 'companies.street' },
        city: { property: 'city', display: 'companies.city' },
        cityCode: { property: 'cityCode', display: 'companies.citycode' },
        countryName: { napropertyme: 'countryName', display: 'glossary.country' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await companyController.getCompanies(filter);
        setCompanies(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [])

    // eslint-disable-next-line 
    useEffect(() => { companyController.retranslate(companies); setCompanies([...companies]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const company = await companyController.create();
        setCompany(company);
        ReactUtil.show('detail');
    }

    const handleDetail = async (id) => {
        const company = await companyController.getCompany(id);
        setCompany(company);
        ReactUtil.show('detail');
    }

    const handleSave = async (company) => {
        await companyController.save(company);
        reload(filter);
    }

    const handleDelete = async (company) => {
        await companyController.remove(company);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!companyController.loaded} />
            <CompanyDetail id='detail' company={company} onSave={handleSave} />
            <CompanyDelete id='delete' company={company} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.companies')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.street} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.city} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.cityCode} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.countryName} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            companies.map((company) => {

                                return (

                                    <tr key={company.id} className={'align-middle' + (company.disabled ? ' text-muted' : '')}>
                                        
                                        <td>{company.name} {company.owner ? ' *' : ''}</td>
                                        <td>{company.street}</td>
                                        <td>{company.city}</td>
                                        <td>{company.cityCode}</td>
                                        <td>{company.country ? company.country.name : '.'}</td>
                                        
                                        <td className='text-center'>
                                            <button className='btn btn-light' onClick={() => handleDetail(company.id)}>
                                                <img src={edit_img} alt='edit' />
                                            </button>
                                        </td>

                                    </tr>
                                )
                            })
                            // ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Companies
