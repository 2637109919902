
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useUserRoleController from '../../../controllers/useUserRoleController.js';

import AppSettings from '../../../logic/AppSettings.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';
import UserRoleDetail from './UserRoleDetail.js';

import edit_img from '../../../assets/images/pen.svg';

const UserRoles = () => {


    /**
     * Page state
    */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppSettings.PAGE_LIMIT, sort: [] });
    const [userrole, setUserRole] = useState({});
    const [userroles, setUserRoles] = useState([]);

    const userRoleController = useUserRoleController();

    const columns = {
        code: { property: 'code', display: 'userroles.code' },
        name: { property: 'name', display: 'userroles.name' },
        description: { name: 'description', display: 'userroles.description' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await userRoleController.getUserRoles(filter);
        setUserRoles(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [])

    // eslint-disable-next-line 
    useEffect(() => { userRoleController.retranslate(userroles); setUserRoles([...userroles]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleDetail = async (id) => {
        const userRole = await userRoleController.getUserRole(id);
        setUserRole(userRole);
        ReactUtil.show('detail');
    }

    const handleSave = async (userRole) => {
        await userRoleController.save(userRole);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!userRoleController.loaded} />
            <UserRoleDetail id='detail' userrole={userrole} onSave={handleSave} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.userroles')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.code} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.description} filter={filter} onChange={handleFilter} />

                            <th></th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            userroles.map((userRole) => (
                                <tr key={userRole.id} className='align-middle'>

                                    <td>{userRole.code}</td>
                                    <td>{userRole.name}</td>
                                    <td>{userRole.description}</td>

                                    <td className='text-center'>
                                        <button className='btn btn-light' onClick={() => handleDetail(userRole.id)}>
                                            <img src={edit_img} alt='edit' />
                                        </button>
                                    </td>
                                    
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default UserRoles
