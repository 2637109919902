
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';

import useUserController from '../controllers/useUserController.js';
import useCompanyController from '../controllers/useCompanyController.js';
import useCatalogController from '../controllers/useCatalogController.js';
import SurveyService from '../services/SurveyService.js';

const useSurveyController = (ilter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);
    
    const userController = useUserController();
    const companyController = useCompanyController();
    const catalogController = useCatalogController();

    /**
     * Functionalities
     */

    const getSurveys = async (filter) => {

        try {
            setLoaded(false);
            const data = await SurveyService.getSurveys(auth.company, filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (survey) => {
        return survey.id != null && await SurveyService.isDeletable(survey);
    }

    const getSurvey = async (id) => {

        const surveyId = id == null || id.trim().length === 0 || id === 'new' ? null : id;

        const survey = surveyId 
            ? await SurveyService.getSurvey(surveyId)
            : await SurveyService.create(auth.company, auth.user, auth.lang);

        translate(survey);

        // Get the categories from the catalog
        survey.categories = survey.catalog?.children ?? [];
    
        return survey;
    }

    const save = async (survey) => {

        try {
            untranslate(survey);
            delete survey.categories;
            survey.id = await SurveyService.save(survey);
        }
        finally {
            translate(survey);
        }
    }

    const clone = async (survey) => {
        await SurveyService.clone(survey);
    }

    const sendInvitations = async (survey) => {

        try {
            untranslate(survey);
            delete survey.categories;
            survey.id = await SurveyService.sendInvitations(survey);
            const updatedSurvey = await getSurvey(survey.id);
            survey.invitations = updatedSurvey.invitations;
        }
        finally {
            translate(survey);
        }
    }

    const remove = async (survey) => {
        await SurveyService.remove(survey);
    }

    const translate = (survey) => {
        survey.company && companyController.translate(survey.company);
        survey.owner && userController.translate(survey.owner);
        survey.catalog && catalogController.translate(survey.catalog);
    }

    const untranslate = (survey) => {
        survey.company && companyController.untranslate(survey.company);
        survey.owner && userController.untranslate(survey.owner);
        survey.catalog && catalogController.untranslate(survey.catalog);
    }

    const retranslate = (surveys) => {

        surveys.forEach(survey => {
            untranslate(survey);
            translate(survey, auth.lang);
        })
    }

    return {
        getSurveys,
        getSurvey,
        loaded,
        deletable,
        save,
        clone,
        sendInvitations,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useSurveyController;