
import axios from "axios";
import AppSettings from '../logic/AppSettings.js';

const BACKEND_URL = `${AppSettings.BACKEND_URL}/login`;

const AuthenticationService = () => {

    const login = async (credentials) => {
        return (await axios.post(`${BACKEND_URL}`, credentials)).data.payload;
    }

    const autologin = async (token) => {
        return (await axios.post(`${BACKEND_URL}/token`, { token })).data.payload;
    }

    const register = async (user) => {
        return (await axios.post(`${BACKEND_URL}/register`, user )).data.payload;
    }

    const reset = async (email) => {
        return (await axios.post(`${BACKEND_URL}/reset`, { email })).data.payload;
    }

    const password = async (code, password) => {
        return (await axios.post(`${BACKEND_URL}/password`, { code, password })).data.payload;
    }
    
    const check = async (code) => {
        return (await axios.post(`${BACKEND_URL}/check`, { code })).data.payload;
    }

    return {
        login,
        autologin,
        register,
        reset,
        password,
        check,
    }
}

export default AuthenticationService();