
import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import './DateSelector.css';

/**
 * DatePicker wrapper
 * https://www.npmjs.com/package/react-date-picker
 */
const DateSelector = ({ className, value, format, onChange }) => {
    return (
        <div className={className}>
            <DatePicker className='date-picker' value={value} format={format ?? 'dd.MM.yyyy'} onChange={onChange} />
        </div>
    )
}

export default DateSelector;