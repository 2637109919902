
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from './Tooltip.js';
import StringUtil from '../../utils/StringUtil.js';
import TreeTable from '../treetable/TreeTable.js';
import LevelSelector from '../selector/LevelSelector.js';
import CatalogLogic from '../../logic/CatalogLogic.js';

import './DeclarativeView.css';
import useTooltips from '../../hooks/useTooltips.js';

const DeclarativeViewEditable = ({ category, disabled = false, review = false, onChange }) => {

    const { t } = useTranslation();

    /**
     * Page state
     */

    const [skilltree, setSkillTree] = useState(category ?? { children: [] });

    /**
     * Page lifecycle
     */

    useTooltips();    

    useEffect(() => {

        (async () => {
            const skilltree = { id: 'root', label: t('glossary.skills'), state: { opened: true }, children: category.children }
            setSkillTree(skilltree);
        })()

        // eslint-disable-next-line 
    }, [category]);

    /**
     * Event handlers
     */

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(category, skill, level);

        // And propagates the category change to the owner
        onChange({ ...category, children: skilltree.children });
    }

    return (

        <div className='container-fluid'>

            {
                !StringUtil.isEmpty(category.description) &&
                <div className='border rounded text-center mx-3 mb-3 p-3'>{category.description}</div>
            }

            <TreeTable className='table table-hover table-declarative border' data={skilltree} onChange={(tree) => setSkillTree(tree)}>
                <tbody>
                    {

                        (skill) => {

                            const hasTooltip = true; //!StringUtil.isEmpty(skill.description);
                            const level = CatalogLogic.getSkillLevel(category, skill);
                            const showLevel = CatalogLogic.isLeaf(skill) && CatalogLogic.hasLevel(skill);

                            return (
                                <tr>
                                    <td>
                                        {
                                            hasTooltip &&
                                            <Tooltip title={skill.description} content={skill.label} />
                                        }
                                        {
                                            !hasTooltip &&
                                            skill.label
                                        }
                                    </td>
                                    <td>
                                        {
                                            showLevel &&
                                            <LevelSelector levels={category.levels} value={level} userLevel={skill.userLevel} reviews={skill.reviews} disabled={disabled} onChange={(value) => handleLevelChange(skill, value)} />
                                        }

                                    </td>
                                </tr>
                            )
                        }
                    }
                </tbody>
            </TreeTable>

        </div>

    )
}

export default DeclarativeViewEditable
