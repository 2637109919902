
import axios from "axios";
import AppSettings from '../logic/AppSettings.js';

const BACKEND_URL = `${AppSettings.BACKEND_URL}/surveys`;

const SurveyService = () => {

    const getSurvey = async (id) => {
        return (await axios.get(`${BACKEND_URL}/${id}`)).data.payload;
    }

    const getSurveys = async (company, filter = null) => {

        let query = company || filter ? '?' : '';
        if (company) query += 'company=' + company.id + (filter ? '&' : '');

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${BACKEND_URL}${query}`)).data.payload;
    }

    const create = async (company, owner, lang) => {
        return (await axios.get(`${BACKEND_URL}/create?company=${company.id}&owner=${owner.id}&lang=${lang}`)).data.payload;
    }

    const isDeletable = async (survey) => {
        return (await axios.get(`${BACKEND_URL}/${survey.id}/deletable`)).data.payload;
    }
    
    const save = async (survey) => {
        return survey.id
        ? (await axios.put(`${BACKEND_URL}/${survey.id}`, survey)).data.payload
        : (await axios.post(`${BACKEND_URL}`, survey)).data.payload;
    }
    
    const clone = async (survey) => {
        return (await axios.post(`${BACKEND_URL}/${survey.id}/clone`)).data.payload;
    }

    const sendInvitations = async (survey) => {
        return (await axios.post(`${BACKEND_URL}/send/${survey.id}`, survey)).data.payload;
    }

    const remove = async (survey) => {
        await axios.delete(`${BACKEND_URL}/${survey.id}`);
    }

    return {
        getSurveys,
        getSurvey,
        create,
        isDeletable,
        save,
        clone,
        sendInvitations,
        remove
    }
}

export default SurveyService();