
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useWorkplaceController from '../../../controllers/useWorkplaceController.js';

import AppSettings from '../../../logic/AppSettings.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';
import WorkplaceDetail from './WorkplaceDetail.js';
import WorkplaceDelete from './WorkplaceDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Workplaces = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppSettings.PAGE_LIMIT, sort: [] });
    const [workplace, setWorkplace] = useState({});
    const [workplaces, setWorkplaces] = useState([]);

    const workplaceController = useWorkplaceController(filter);

    const columns = {
        code: { property: 'code', display: 'workplaces.code' },
        name: { property: 'name', display: 'workplaces.name' },
        country: { property: 'countryName', display: 'glossary.country' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await workplaceController.getWorkplaces(filter);
        setWorkplaces(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [auth.company])

    // eslint-disable-next-line 
    useEffect(() => { workplaceController.retranslate(workplaces); setWorkplaces([...workplaces]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const workplace = await workplaceController.create();
        ReactUtil.show('detail');
        setWorkplace(workplace);
    }

    const handleDetail = async (id) => {
        const workplace = await workplaceController.getWorkplace(id);
        setWorkplace(workplace);
        ReactUtil.show('detail');
    }

    const handleSave = async (workplace) => {
        await workplaceController.save(workplace);
        reload(filter);
    }

    const handleDelete = async (workplace) => {
        await workplaceController.remove(workplace);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!workplaceController.loaded} />
            <WorkplaceDetail id='detail' workplace={workplace} onSave={handleSave} />
            <WorkplaceDelete id='delete' workplace={workplace} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.workplaces')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.code} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.country} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            workplaces.map((workplace) => (
                                <tr key={workplace.id} className='align-middle'>
                                    
                                    <td>{workplace.code}</td>
                                    <td>{workplace.name}</td>
                                    <td>{workplace.country ? workplace.country.name : '-'}</td>
                                    
                                    <td className='text-center'>
                                        <button className='btn btn-light' onClick={() => handleDetail(workplace.id)}>
                                            <img src={edit_img} alt='edit' />
                                        </button>
                                    </td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Workplaces
