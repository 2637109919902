
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useInvitationController from '../../../controllers/useInvitationController.js';

import parse from 'html-react-parser';
import Stepper from './Stepper.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import CatalogLogic from '../../../logic/CatalogLogic.js';
import InvitationLogic from '../../../logic/InvitationLogic.js';

import DeclarativeView from '../../../components/catalog/DeclarativeViewEditable.js';
import NormalizedView from '../../../components/catalog/NormalizedViewEditable.js';
import LanguageView from '../../../components/catalog/LanguageViewEditable.js';
import MindsetView from './MindsetView.js';

import next_img from '../../../assets/images/next.svg';
import previous_img from '../../../assets/images/previous.svg';

const SurveyDetail = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const { id } = useParams();
    const navigate = useNavigate();

    const [invitation, setInvitation] = useState({});
    const [catalog, setCatalog] = useState({});
    const [mindset, setMindset] = useState({});
    const [steps, setSteps] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [currentStep, setCurrentStep] = useState(0);
    const [lastStep, setLastStep] = useState(0);
    const [surveyCompleted, setSurveyCompleted] = useState(false);
    const [surveySubmitable, setSurveySubmitable] = useState(false);

    const invitationController = useInvitationController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);

                const invitation = await invitationController.getInvitation(id);
                const surveyCompleted = invitation.status === 'COMPLETED';
                const surveySubmitable = InvitationLogic.isSubmitable(invitation);
                const currentStep = surveyCompleted ? 0 : invitation.step;
                const steps = InvitationLogic.createSteps(invitation);

                const mindset = { 
                    step: invitation.mindsetStep,
                    questions: invitation.mindsetQuestions,
                    responses: invitation.mindsetResponses,
                }

                setInvitation(invitation);
                setCatalog(invitation.catalog);
                setMindset(mindset);
                setSteps(steps);

                setCurrentStep(currentStep);
                setLastStep(steps.length - 1);
                setSurveyCompleted(surveyCompleted);
                setSurveySubmitable(surveySubmitable);
            }
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line
    }, [id, auth.lang]);

    /**
     * 
     * Event handlers
     */

    const handleCategoryChange = (category) => {
        CatalogLogic.mergeCategory(catalog, category);
        setCatalog({ ...catalog });
    }

    const handleMindsetChange = (mindset) => {
        
        invitation.mindsetStep = mindset.step;
        invitation.mindsetResponses = mindset.responses;

        setMindset(mindset);
        setInvitation(invitation);
        invitationController.update(invitation);
    }

    const handleNext = async () => {

        if (currentStep < lastStep) {

            const newCurrentStep = Math.min(currentStep + 1, lastStep);

            setCurrentStep(newCurrentStep);
            invitation.step = newCurrentStep;
            invitationController.update(invitation);
            setSurveySubmitable(InvitationLogic.isSubmitable(invitation));
        }
    }

    const handlePrev = async () => {

        if (currentStep > 0) {

            const newCurrentStep = Math.max(currentStep - 1, 0);

            setCurrentStep(newCurrentStep);
            invitation.step = newCurrentStep;
            invitationController.update(invitation);
            setSurveySubmitable(InvitationLogic.isSubmitable(invitation));
        }
    }

    const handleSubmit = async () => {
        await invitationController.completed(invitation);
        navigate('/user/invitations');
    }

    const handleCancel = () => {
        navigate('/user/invitations');
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!loaded} />

            <div className='row p-4'>

                <h5 className='text-muted'>{invitation.survey?.name}</h5>

                <hr />

                <div className='d-flex justify-content-between align-items-stretch'>

                    <button type='button' className='btn btn-light' disabled={currentStep < 1} onClick={handlePrev}>
                        <img src={previous_img} alt='back' />
                    </button>

                    <Stepper steps={steps} currentStep={currentStep} />

                    <button type='button' className='btn btn-light' disabled={currentStep >= lastStep} onClick={handleNext}>
                        <img src={next_img} alt='next' />
                    </button>

                </div>

                <div className='container'>
                    <div className='row'>
                        {
                            steps.map((step, i) => (

                                <div key={step.id} className={'stepper-panel p-4 fade' + (currentStep === i ? ' show active' : '')}>

                                    {
                                        step.type === 'start' &&
                                        parse(t('invitations.startmsg'))
                                    }
                                    {
                                        step.type === 'declarative' &&
                                        <DeclarativeView category={step} disabled={surveyCompleted} onChange={handleCategoryChange} />
                                    }
                                    {
                                        step.type === 'normalized' &&
                                        <NormalizedView category={step} disabled={surveyCompleted} onChange={handleCategoryChange} />
                                    }
                                    {
                                        step.type === 'language' &&
                                        <LanguageView category={step} disabled={surveyCompleted} onChange={handleCategoryChange} />
                                    }
                                    {
                                        step.type === 'mindset' &&
                                        <MindsetView mindset={mindset} disabled={surveyCompleted} onChange={handleMindsetChange} />
                                    }
                                    {
                                        step.type === 'end' &&
                                        parse(t('invitations.endmsg'))
                                    }

                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='row pt-3'>
                    <hr />
                    <div className='d-flex justify-content-between w-100'>
                        <div>
                            <button type='button' className='btn btn-light' disabled={currentStep < 1} onClick={handlePrev}>{t('button.previous')}</button>
                            <button type='button' className='btn btn-light' disabled={currentStep >= lastStep} onClick={handleNext}>{t('button.next')}</button>
                        </div>
                        <div className='btn-group'>
                            <button className='btn btn-light' disabled={!surveySubmitable} onClick={handleSubmit}>{t('button.submit')}</button>
                            <button className='btn btn-light' onClick={handleCancel}>{t('button.quit')}</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default SurveyDetail;
