
import config from '../../package.json';

const AppSettings = () => {

    // const REACT_APP_A XIOS_REQUEST = process.env.REACT_APP_AXIOS_REQUEST ?? false;

    return {
        VERSION: config.version,
        FRONTEND_URL: config.frontendUrl,
        BACKEND_URL: config.backendUrl,
        PAGE_LIMIT: config.pageLimit ?? 50,
        HTTP_REQUESTS: config.httpRequests ?? false
    }
}

export default AppSettings();

