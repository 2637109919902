
import { useTranslation } from 'react-i18next';
import CatalogLogic from '../../../logic/CatalogLogic.js';
import DateSelector from '../../../components/editor/DateSelector.js';

const TabOptions = ({ survey, categories, onChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();

    /**
     * Event handlers
     */

    const handleChange = (attribute) => {
        onChange({ ...survey, ...attribute });
    }

    const handleToggle = (category) => {
        category.state.selected = !category.state.selected;
        onChange({ ...survey });
    }

    const handleToggleMindset = () => {
        onChange({ ...survey, mindset: !survey.mindset });
    }

    return (

        <div className='container'>

            <div className='row'>

                <div className='col-5'>
                    <label>{t('surveys.name')}</label>
                    <input type='text' id='name' className='form-control' defaultValue={survey.name ?? ''} placeholder={t('surveys.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                </div>

                <div className='col-5'>
                    <label>{t('surveys.duedate')}</label>
                    <DateSelector className='form-control' value={survey.dueDate} onChange={(date) => handleChange({ dueDate: date })} />
                </div>

            </div>

            <fieldset className='row border rounded-3 mt-4 ps-2'>

                <legend className='float-none w-auto fs-6 mx-4 px-4 '>{t('surveys.skilltypes')}</legend>

                <div className='col-sm-4 mb-2 py-3 px-4'>

                    <h6 className='table-label'>{t('surveys.declaratives')}</h6>

                    {
                        categories?.filter(category => CatalogLogic.isDeclarative(category)).map((category, i) => {

                            return (
                                <div key={i} className='form-check ms-2'>
                                    <input type='checkbox' className='form-check-input' checked={category.state.selected ?? false} onChange={() => handleToggle(category)} />
                                    <label className='form-check-label' onClick={(e) => handleToggle(category)}>{category.label}</label>
                                </div>
                            )
                        })
                    }

                </div>

                <div className='col-sm-4 mb-2 py-3 px-4'>

                    <h6 className='table-label'>{t('surveys.normalized')}</h6>

                    {
                        categories?.filter(category => CatalogLogic.isNormalized(category)).map((category, i) => {

                            return (
                                <div key={i} className='form-check ms-2'>
                                    <input type='checkbox' className='form-check-input' checked={category.state.selected ?? false} onChange={() => handleToggle(category)} />
                                    <label className='form-check-label' onClick={(e) => handleToggle(category)}>{category.label}</label>
                                </div>
                            )
                        })
                    }

                    <div className='form-check ms-2'>
                        <input type='checkbox' className='form-check-input' checked={survey.mindset ?? false} onChange={handleToggleMindset} />
                        <label className='form-check-label' onClick={handleToggleMindset}>{t('surveys.mindset')}</label>
                    </div>

                </div>

                <div className='col-sm-4 mb-2 py-3 px-4'>

                    <h6 className='table-label'>{t('surveys.other')}</h6>

                    {
                        categories?.filter(category => !CatalogLogic.isDeclarative(category) && !CatalogLogic.isNormalized(category)).map((category, i) => {

                            return (
                                <div key={i} className='form-check ms-2'>
                                    <input type='checkbox' className='form-check-input' checked={category.state.selected ?? false} onChange={() => handleToggle(category)} />
                                    <label className='form-check-label' onClick={(e) => handleToggle(category)}>{category.label}</label>
                                </div>
                            )
                        })
                    }

                    <div className='form-check ms-2'>
                        <input type='checkbox' className='form-check-input' checked={false} disabled={true} />
                        <label className='form-check-label'>{t('surveys.culture')}</label>
                    </div>

                    <div className='form-check ms-2'>
                        <input type='checkbox' className='form-check-input' checked={false} disabled={true} />
                        <label className='form-check-label'>{t('surveys.projects')}</label>
                    </div>

                </div>

            </fieldset>
        </div>
    )
}

export default TabOptions;
