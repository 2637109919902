
import './Stepper.css';

const Stepper = ({ steps, currentStep, completedStep }) => {

    const completedStep2 = completedStep ?? currentStep;
    
    return (

        <ul className='stepper border rounded'>
            {
                steps.map((step, i) => {


                    // Retrieve spill + link classes
                    const spillClass = i === currentStep ? 'active' : (i <= completedStep2 ? 'completed' : '');
                    const linkClass = (i < completedStep2 ? ' right-completed' : '') + (i > 0 && i <= completedStep2 ? ' left-completed' : '');

                    return (
                        <li key={step.id} className={spillClass + linkClass}>
                            <div>{i+1}</div>
                            <div>{step.label}</div>
                        </li>
                    )

                })
            }
        </ul>
    )
}

export default Stepper;
