
const Tooltip = ({ title, content, children}) => {

    return (
        <div data-bs-toggle='tooltip' data-bs-title={title} data-bs-placement='right' data-bs-html='true' data-bs-delay='{"show":1000,"hide":150}'>
            { children || content }
        </div>
    )
}

export default Tooltip;