
import axios from "axios";
import AppSettings from '../logic/AppSettings.js';

const BACKEND_URL = `${AppSettings.BACKEND_URL}/userroles`;

const UserRoleService = () => {

    const getUserRoles = async (filter) => {

        let query = filter ? '?' : '';

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${BACKEND_URL}${query}`)).data.payload;
    }

    const getUserRole = async (id) => {
        return (await axios.get(`${BACKEND_URL}/${id}`)).data.payload;
    }

    const save = async (userRole) => {

        return userRole.id
            ? (await axios.put(`${BACKEND_URL}/${userRole.id}`, userRole)).data.payload
            : (await axios.post(`${BACKEND_URL}`, userRole)).data.payload;
    }

    return {
        getUserRole,
        getUserRoles,
        save
    }
}

export default UserRoleService();