
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTooltips from '../../../hooks/useTooltips.js';
import useInvitationController from '../../../controllers/useInvitationController.js';

import SearchBar from '../../../components/filter/SearchBar.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import InvitationLogic from '../../../logic/InvitationLogic.js';
import SurveyLogic from '../../../logic/SurveyLogic.js';
import FilterUtil from '../../../utils/FilterUtil.js';
import InvitationAdd from './InvitationAdd.js';
import InvitationCreate from './InvitationCreate.js';
import InvitationDel from './InvitationDel.js';
import InvitationSend from './InvitationSend.js';
import InvitationSent from '../../../components/alert/InvitationSent.js';

import create_img from '../../../assets/images/new.svg';
import add_img from '../../../assets/images/plus.svg';
import delete_img from '../../../assets/images/trash.svg';
import send_disabled_img from '../../../assets/images/send-disabled.svg';
import send_enabled_img from '../../../assets/images/send-enabled.svg';
import Tooltip from '../../../components/catalog/Tooltip.js';

const TabInvitations = ({ survey, onChange, onSend }) => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'userEmail', display: t('users.email') },
            { name: 'userFirstname', display: t('users.firstname') },
            { name: 'userLastname', display: t('users.lastname') },
            { name: 'userCompanyName', display: t('users.company') },
            { name: 'status', display: t('users.status') },
            { name: 'userTeam', display: t('users.team') },
        ]
    }

    /**
     * Page state
     */

    const [filter, setFilter] = useState(defaultFilter);
    const [allSelected, setAllSelected] = useState(false);
    const [oneSelected, setOneSelected] = useState(false);
    const visibleInvitations = InvitationLogic.getVisibles(survey.invitations);
    const [showDialog, setShowDialog] = useState(false);

    const invitationController = useInvitationController();

    /**
     * Page lifecycle
     */

    useTooltips();

    useEffect(() => {

        if (survey.invitations) {

            // First all invitations are hidden
            InvitationLogic.markAsHidden(survey.invitations);

            // Filter the invitations and make those matching the visible criteria
            const selected = FilterUtil.filter(survey.invitations, filter);
            InvitationLogic.markAsVisible(selected);

            // Get visible invitations
            const visibles = InvitationLogic.getVisibles(survey.invitations);

            // Update button states
            const allSelected = InvitationLogic.isAllSelected(visibles);
            const oneSelected = InvitationLogic.isOneSelected(visibles);

            setAllSelected(allSelected);
            setOneSelected(oneSelected);
        }

        // eslint-disable-next-line 
    }, [filter]);

    /**
     * Event handlers
     */

    const handleToggleOne = (invitation) => {
        InvitationLogic.toggleSelected(invitation);
        setFilter({ ...filter });
    }

    const handleToggleAll = () => {
        allSelected ? InvitationLogic.markAsUnselected(survey.invitations) : InvitationLogic.markAsSelected(survey.invitations);
        setFilter({ ...filter });
    }

    const handleCreate = async (user) => {
        const invitation = await invitationController.create(survey, user);
        invitation.visible = true;
        survey.invitations.push(invitation);
        setFilter({ ...filter });
        onChange(survey.invitations);
    }

    const handleAdd = (invitations) => {
        InvitationLogic.markAsUnselected(invitations);
        InvitationLogic.markAsVisible(invitations);
        survey.invitations = [...survey.invitations, ...invitations];
        setFilter({ ...filter });
        onChange(survey.invitations);
    }

    const handleDelete = async () => {

        const invitations = InvitationLogic.getSelected(survey.invitations);
        InvitationLogic.markAsDeleted(invitations);

        setFilter({ ...filter });
        onChange(survey.invitations);
    }

    const handleSend = async (mailfields) => {
        setAllSelected(false);
        survey.mailfields = mailfields;
        onSend(survey, () => setShowDialog(true));

    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <InvitationAdd id='add' survey={survey} onAdd={handleAdd} />
            <InvitationCreate id='new' onCreate={handleCreate} />
            <InvitationDel id='del' onDelete={handleDelete} />
            <InvitationSend id='send' survey={survey} onSend={handleSend} />
            <InvitationSent show={showDialog} onClose={() => setShowDialog(false)} />

            <div className='row'>

                <div className='d-flex justify-content-between align-items-center pb-1'>

                    <div className='btn-group'>

                        <button type='button' className='btn btn-light' disabled={!oneSelected} data-bs-toggle='modal' data-bs-target='#send'>
                            <Tooltip title={t('invitations.tooltip-send')}>
                                <img src={oneSelected ? send_enabled_img : send_disabled_img} alt='send' />
                            </Tooltip>
                        </button>

                        <button type='button' className='btn btn-light' data-bs-toggle='modal' data-bs-target='#add'>
                            <Tooltip title={t('invitations.tooltip-add')}>
                                <img src={add_img} alt='add' />
                            </Tooltip>
                        </button>

                        <button type='button' className='btn btn-light' data-bs-toggle='modal' data-bs-target='#new'>
                            <Tooltip title={t('invitations.tooltip-create')}>
                                <img src={create_img} alt='create' />
                            </Tooltip>
                        </button>

                        <button type='button' className='btn btn-light' disabled={!oneSelected} data-bs-toggle='modal' data-bs-target='#del'>
                            <Tooltip title={t('invitations.tooltip-delete')}>
                                <img src={delete_img} alt='del' />
                            </Tooltip>
                        </button>

                    </div>

                    <SearchBar className='ms-4' items={survey.invitations} filter={filter} onSearch={handleFilter} />

                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='userEmail' filter={filter} onFilter={handleFilter}>
                                    {t('users.email')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='userFirstname' filter={filter} onFilter={handleFilter}>
                                    {t('users.firstname')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='userLastname' filter={filter} onFilter={handleFilter}>
                                    {t('users.lastname')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='userCompanyName' filter={filter} onFilter={handleFilter}>
                                    {t('glossary.company')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='status' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.status')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <input type='checkbox' className='form-check-input' checked={allSelected} onChange={handleToggleAll} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            visibleInvitations.map((invitation, i) => (
                                <tr key={i} className='align-middle'>
                                    <td>{invitation.user.email}</td>
                                    <td>{invitation.user.firstname}</td>
                                    <td>{invitation.user.lastname}</td>
                                    <td>{invitation.user.company.name}</td>
                                    <td>{SurveyLogic.toStringStatus(invitation.status)}</td>
                                    <td className='text-center'>
                                        <input type='checkbox' className='form-check-input' checked={invitation.selected ?? false} onChange={() => handleToggleOne(invitation)} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default TabInvitations;
