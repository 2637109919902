
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useAssessmentController, { AssessmentStatus } from '../../../controllers/useAssessmentController.js';

import AppSettings from '../../../logic/AppSettings.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import DateUtil from '../../../utils/DateUtil.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';
import AssessmentDetail from './AssessmentDetail.js';
import AssessmentDelete from './AssessmentDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';
import report_img from '../../../assets/images/report.svg';

const Assessments = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppSettings.PAGE_LIMIT, sort: [] });
    const [assessment, setAssessment] = useState({});
    const [assessments, setAssessments] = useState([]);
    const assessmentTypes = t('assessments.types');
    const assessmentStatuses = t('assessments.statuses');

    const assessmentController = useAssessmentController();

    const columns = {
        start: { property: 'start', display: 'assessments.date' },
        code: { property: 'code', display: 'assessments.code' },
        type: { property: 'assessmentType', display: 'assessments.type' },
        end: { property: 'end', display: 'assessments.status-label' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await assessmentController.getAssessments(filter);
        setAssessments(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [])

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const assessment = await assessmentController.create(auth.user);
        setAssessment(assessment);
        ReactUtil.show('detail');
    }
    
    const handleDetail = async (id) => {
        const assessment = await assessmentController.getAssessment(id);
        setAssessment(assessment);
        ReactUtil.show('detail');
    }

    const handleDelete = async (assessment) => {
        assessmentController.remove(assessment);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    const handleStart = () => {
        assessmentController.start(assessment);
        reload(filter);
    }

    const handleResume = () => {
        assessmentController.resume(assessment);
        reload(filter);
    }

    const handleReport = (assessment) => {
        assessmentController.report(assessment);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!assessmentController.loaded} />
            <AssessmentDetail id='detail' assessment={assessment} onStart={handleStart} onResume={handleResume} onReport={handleReport} />
            <AssessmentDelete id='delete' assessment={assessment} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('assessments.title')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.start} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.code} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.type} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.end} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            assessments.map((assessment) => {

                                const assessmentType = assessmentTypes.find(type => type.id === assessment.type);
                                const assessmentStatus = assessmentStatuses.find(status => status.id === assessment.status);

                                return (
                                    <tr key={assessment.id} className='align-middle'>

                                        <td>{DateUtil.toLocaleString(assessment.start) ?? '-'}</td>
                                        <td>{assessment?.externalId ?? '-'}</td>
                                        <td>{assessmentType?.name ?? '-'}</td>
                                        <td>{assessmentStatus?.name ?? '-'}</td>

                                        <td className='text-center'>
                                            <div className='btn-group'>
                                                
                                                <button className='btn btn-light' onClick={() => handleDetail(assessment.id)}>
                                                    <img src={edit_img} alt='edit' />
                                                </button>
                                                
                                                {
                                                    assessmentStatus.id === AssessmentStatus.COMPLETED 
                                                    && <button className='btn btn-light' onClick={() => handleReport(assessment)}>
                                                        <img src={report_img} alt='report' />
                                                    </button>
                                                }

                                            </div>
                                        </td>

                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Assessments
