
import axios from "axios";
import AppConfig from '../logic/AppSettings.js';

const BACKEND_URL = `${AppConfig.BACKEND_URL}/assessments`;

const AssessmentService = () => {

    const getAssessments = async (user, filter = null) => {

        let query = user || filter ? '?' : '';
        if (user) query += 'user=' + user.id + (filter ? '&' : '');

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${BACKEND_URL}${query}`)).data.payload;
    }

    const getAssessment = async (id) => {
        return (await axios.get(`${BACKEND_URL}/${id}`)).data.payload;
    }

    const create = async (user) => {
        return (await axios.get(`${BACKEND_URL}/create?user=${user.id}`)).data.payload;
    }

    const isDeletable = async (assessment) => {
        return (await axios.get(`${BACKEND_URL}/${assessment.id}/deletable`)).data.payload;
    }

    const save = async (assessment) => {

        return assessment.id
            ? (await axios.put(`${BACKEND_URL}/${assessment.id}`, assessment)).data.payload
            : (await axios.post(`${BACKEND_URL}`, assessment)).data.payload;
    }

    const remove = async (assessment) => {
        await axios.delete(`${BACKEND_URL}/${assessment.id}`);
    }

    const start = async (assessment) => {
        return (await axios.put(`${BACKEND_URL}/${assessment.id}/start`)).data.payload;
    }
    
    const resume = async (assessment) => {
        return (await axios.put(`${BACKEND_URL}/${assessment.id}/resume`)).data.payload;
    }
    
    const report = async (assessment) => {
        return (await axios.get(`${BACKEND_URL}/${assessment.id}/report`)).data.payload;
    }    
    
    return {
        getAssessments,
        getAssessment,
        create,
        isDeletable,
        save,
        remove,
        start,
        resume,
        report
    }
}

export default AssessmentService();