
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useCurriculumController from '../../../controllers/useCurriculumController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import CurriculumView from '../../../components/curriculum/CurriculumView.js';

const Curriculum = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const { id, uid } = useParams();

    const [user, setUser] = useState({});
    const [catalog, setCatalog] = useState({});
    const [formations, setFormations] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const curriculumController = useCurriculumController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);

                const data = await curriculumController.getPrivateCurriculum(uid, id, auth.lang);
                
                setUser(data.user);
                setCatalog(data.catalog);
                setFormations(data.formations);
                setExperiences(data.experiences);
            }
            finally {
                setLoaded(true);
            }

        })()    

        // eslint-disable-next-line 
    }, [auth.lang, curriculumController.loaded]);

    return (

        <div className='container'>
            <div className='row p-4'>

                <LoadingIcon loading={!loaded} />

                <div className='d-flex align-items-center justify-content-between'>
                    <h5 className='text-muted'>{t('mycv.curriculum')}</h5>
                </div>

                <hr />

                <CurriculumView user={user} catalog={catalog} formations={formations} experiences={experiences} showExpectedValues={true} />

            </div>
        </div >
    )
}

export default Curriculum
