
import { useTranslation } from 'react-i18next';
import DateUtil from '../../utils/DateUtil.js';
import hr360_img from '../../assets/images/review-spill.svg';

import './LevelSelector.css'

const LevelSelector = ({ levels, value, userLevel = null, reviews = [], disabled = false, onChange }) => {

    /**
     * Component state
     */

    const { t } = useTranslation();

    return (
        <div className='level-selector btn-group'>
            {
                [...Array((levels ?? 3) + 1).keys()].slice(1).map(level => {

                    const reviewsForLevel = reviews.filter(r => r.level === level);
                    const reviewText = `<div class='text-start'><strong>${t('glossary.reviews')}</strong>${reviewsForLevel.map(review => `<div>${DateUtil.toLocaleString(new Date(review.date))} ${review.firstname} ${review.lastname}</div>`).join('')}</div>`;

                    return (

                        <div key={level} className='btn-group'>

                            <input className='btn-check' type='radio' checked={level === value} disabled={disabled} onChange={() => onChange(level)} />

                            <label className={'btn btn-sm btn-outline-primary' + (level === userLevel && level !== value ? ' userlevel' : '')} onClick={() => onChange(level)}>
                                {level}
                            </label>

                            {
                                reviewsForLevel.length > 0 &&
                                <div className='spillgroup' data-bs-toggle='tooltip' data-bs-title={reviewText} data-bs-placement='right' data-bs-html='true'>
                                    {reviewsForLevel.length > 0 && <img src={hr360_img} alt='360' />}
                                    {reviewsForLevel.length > 1 && <img src={hr360_img} alt='360' />}
                                    {reviewsForLevel.length > 2 && <img src={hr360_img} alt='360' />}
                                </div>
                            }

                        </div>

                    )
                })
            }
        </div>
    )
}

export default LevelSelector