
import axios from "axios";
import AppSettings from '../logic/AppSettings.js';

const BACKEND_URL = `${AppSettings.BACKEND_URL}/workplaces`;

const WorkplaceService = () => {

    const getWorkplaces = async (company, filter = null) => {

        let query = company || filter ? '?' : '';
        if (company) query += 'company=' + company.id + (filter ? '&' : '');

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${BACKEND_URL}${query}`)).data.payload;
    }

    const getWorkplace = async (id) => {
        return (await axios.get(`${BACKEND_URL}/${id}`)).data.payload;
    }

    const create = async (company) => {
        return (await axios.get(`${BACKEND_URL}/create?company=${company.id}`)).data.payload;
    }

    const isDeletable = async (workspace) => {
        return (await axios.get(`${BACKEND_URL}/${workspace.id}/deletable`)).data.payload;
    }

    const save = async (workplace) => {

        return workplace.id
            ? (await axios.put(`${BACKEND_URL}/${workplace.id}`, workplace)).data.payload
            : (await axios.post(`${BACKEND_URL}`, workplace)).data.payload;
    }

    const remove = async (workplace) => {
        await axios.delete(`${BACKEND_URL}/${workplace.id}`);
    }

    return {
        getWorkplaces,
        getWorkplace,
        create,
        isDeletable,
        save,
        remove
    }
}

export default WorkplaceService();