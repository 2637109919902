
import { useTranslation } from 'react-i18next';
import Slider from '../selector/Slider.js';
import CatalogLogic from '../../logic/CatalogLogic.js';
import StringUtil from '../../utils/StringUtil.js';

import './LanguageView.css';
import Tooltip from './Tooltip.js';

const LanguageViewSelectable = ({ category, onChange }) => {

    /**
     * Page state
     */
    const { t } = useTranslation();
    const labels = t('common.language-view-labels');

    /**
     * Event handlers
     */

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(category, skill, level);

        // And propagates the category change to the owner
        onChange({ ...category });
    }

    const handleToggle = (skill) => {

        // Toggle selection state
        CatalogLogic.toogleSelection(category, skill);

        // And propagates the category change to the owner
        onChange({ ...category });
    }

    const handleHeaderClick = (skill, i) => {

        if (skill.state?.selected) {

            // Change all children level
            CatalogLogic.setChildrenLevel(category, skill, i + 1);

            // And propagates the category change to the owner
            onChange({ ...category });
        }
    }

    return category && category.children?.map(skill => {

        const disabled = skill.state.selected ? false : true;

        return (
            <div key={skill.id} className='container mb-3'>

                <table className='table table-language'>
                    <thead>
                    <tr>
                            <th className='text-start'>
                                <input id={skill.id} type='checkbox' className='form-check-input me-2' checked={disabled ? false : true} onChange={() => handleToggle(skill)} />
                                <label onClick={() => handleToggle(skill)}>{skill.label}</label>
                            </th>
                            <th>
                                <div className='table-header'>
                                    <div className={disabled ? ' text-muted' : ''}>
                                        {
                                            labels.map((label, i) => <div key={i} onClick={() => handleHeaderClick(skill, i)}>{label}</div>)
                                        }
                                    </div>
                                    <button
                                        type='button'
                                        className='btn btn-xs btn-danger'>
                                        {t('glossary.delete')}
                                    </button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            skill.children?.map((skill, i) => {

                                const level = CatalogLogic.getSkillLevel(category, skill);
                                const hasTooltip = !StringUtil.isEmpty(skill.description);

                                return (
                                    <tr key={skill.id}>
                                        <td className={'text-start ps-4' + (disabled ? ' text-muted' : '')}>
                                            {
                                                hasTooltip 
                                                    ? <Tooltip title={skill.description} content={skill.label} />
                                                    : skill.label
                                            }
                                        </td>
                                        <td>
                                            {
                                                <Slider min='1' max='6' value={level} disabled={disabled} userLevel={skill.userLevel} reviews={skill.reviews} onChange={(e) => handleLevelChange(skill, e.target.value)} />
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        )
    })
}

export default LanguageViewSelectable;
