
import axios from "axios";
import AppSettings from '../logic/AppSettings.js';

const CurriculumService = () => {

    const getPublicCurriculum = async (userId) => {
        return (await axios.get(`${AppSettings.BACKEND_URL}/curriculum2/${userId}`)).data.payload;
    }

    const getPrivateCurriculum = async (userId, motionId) => {

        const url = motionId
            ? `${AppSettings.BACKEND_URL}/curriculum/${userId}/${motionId}`
            : `${AppSettings.BACKEND_URL}/curriculum/${userId}`;

        return (await axios.get(url)).data.payload;
    }


    return {
        getPublicCurriculum,
        getPrivateCurriculum
    }
}

export default CurriculumService();