
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import usePositionTypeController from '../../../controllers/usePositionTypeController.js';

import AppSettings from '../../../logic/AppSettings.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';
import PositionTypeDetail from './PositionTypeDetail.js';
import PositionTypeDelete from './PositionTypeDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';
import key_img from '../../../assets/images/check.svg';

const PositionTypes = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppSettings.PAGE_LIMIT, sort: [] });
    const [positionType, setPositionType] = useState({});
    const [positionTypes, setPositionTypes] = useState([]);

    const positionTypeController = usePositionTypeController(filter);

    const columns = {
        code: { property: 'code', display: 'postypes.code' },
        name: { property: 'name', display: 'postypes.name' },
        keyrole: { property: 'keyrole', display: 'postypes.keyrole' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await positionTypeController.getPositionTypes(filter);
        setPositionTypes(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [auth.company])

    // eslint-disable-next-line 
    useEffect(() => { positionTypeController.retranslate(positionTypes); setPositionTypes([...positionTypes]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const postype = await positionTypeController.create();
        setPositionType(postype);
        ReactUtil.show('detail');
    }

    const handleDetail = async (id) => {
        const postype = await positionTypeController.getPositionType(id);
        setPositionType(postype);
        ReactUtil.show('detail');
    }

    const handleSave = async (positionType) => {
        await positionTypeController.save(positionType);
        reload(filter);
    }

    const handleDelete = async (positionType) => {
        await positionTypeController.remove(positionType);
        reload(filter);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!positionTypeController.loaded} />
            <PositionTypeDetail id='detail' positionType={positionType} onSave={handleSave} />
            <PositionTypeDelete id='delete' positionType={positionType} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.postypes')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.code} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.keyrole} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            positionTypes.map((postype) => (
                                <tr key={postype.id} className='align-middle'>

                                    <td>{postype.code}</td>
                                    <td>{postype.name}</td>
                                    <td>{postype.keyrole ? <img className='keyrole-check' src={key_img} alt='check' /> : ''}</td>

                                    <td className='text-center'>
                                        <button className='btn btn-light' onClick={() => handleDetail(postype.id)}>
                                            <img src={edit_img} alt='edit' />
                                        </button>
                                    </td>
                                    
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default PositionTypes
