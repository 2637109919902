
import useExperienceController from './useExperienceController.js';
import useFormationController from './useFormationController.js';
import CurriculumService from '../services/CurriculumService.js';
import LangUtil from "../utils/LangUtil.js";

const useCurriculumController = () => {

    const experienceController = useExperienceController();
    const formationController = useFormationController();

    const getPublicCurriculum = async (userId, lang) => {

        const data = await CurriculumService.getPublicCurriculum(userId);

        LangUtil.translateTree(data.catalog, lang);
        data.experiences.forEach(e => experienceController.translate(e, lang));
        data.formations.forEach(e => formationController.translate(e, lang));

        return data;
    }

    const getPrivateCurriculum = async (userId, motionId = null, lang) => {

        const data = motionId
            ? await CurriculumService.getPrivateCurriculum(userId, motionId)
            : await CurriculumService.getPrivateCurriculum(userId);

        LangUtil.translateTree(data.catalog, lang);
        data.motionCatalog && LangUtil.translateTree(data.motionCatalog, lang);

        data.experiences.forEach(e => experienceController.translate(e, lang));
        data.formations.forEach(e => formationController.translate(e, lang));

        return data;
    }

    return {
        getPublicCurriculum,
        getPrivateCurriculum
    }
}

export default useCurriculumController;