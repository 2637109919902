
import { useTranslation } from 'react-i18next';
import './ButtonBar.css';

const ButtonBar = ({ className, question, response, onClick }) => {

    const ButtonBar = [];
    const { t } = useTranslation();

    for (let i = 0; i < question.levels; i++) {

        if (response === i + 1) {
            ButtonBar.push(<button key={i} className='btn btn-secondary active'></button>);
        }
        else {
            ButtonBar.push(<button key={i} className='btn btn-light' onClick={() => onClick(i+1)}></button>);
        }
    }

    return (
        <div className={'buttonbar ' + className}>
            <span>{t('invitations.disagree')}</span>
            {ButtonBar}
            <span>{t('invitations.agree')}</span>
        </div>
    )
}

export default ButtonBar
