
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useFormationController from '../../../controllers/useFormationController.js';

import AppSettings from '../../../logic/AppSettings.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';
import FormationDetail from './FormationDetail.js';
import FormationDelete from './FormationDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Formations = () => {


    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppSettings.PAGE_LIMIT, sort: [] });
    const [formation, setFormation] = useState({});
    const [formations, setFormations] = useState([]);

    const formationController = useFormationController(auth.user, filter);

    const columns = {
        year: { property: 'year', display: 'formations.year' },
        school: { property: 'school', display: 'formations.school' },
        diploma: { property: 'diploma', display: 'formations.diploma' },
        type: { property: 'diplomaType', display: 'formations.diplomaType' }
    }

    /**
     * Page lifecycle
     */
 
    const reload = async (filter) => {
        const data = await formationController.getFormations(filter);
        setFormations(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [])

    // eslint-disable-next-line 
    useEffect(() => { formationController.retranslate(formations); setFormations([...formations]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const formation = await formationController.create(auth.user);
        setFormation(formation);
        ReactUtil.show('detail');
    }
    
    const handleDetail = async (id) => {
        const formation = await formationController.getFormation(id);
        setFormation(formation);
        ReactUtil.show('detail');
    }

    const handleSave = async (formation) => {
        await formationController.save(formation);
        reload(filter);
    }

    const handleDelete = async (formation) => {
        await formationController.remove(formation);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!formationController.loaded} />
            <FormationDetail id='detail' formation={formation} onSave={handleSave} />
            <FormationDelete id='delete' formation={formation} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('profile.formations')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.year} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.school} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.diploma} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.type} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            formations.map((formation) => (
                                <tr key={formation.id} className='align-middle'>

                                    <td>{formation.year ?? '-'}</td>
                                    <td>{formation.school ?? '-'}</td>
                                    <td>{formation.diploma ?? '-'}</td>
                                    <td>{formation.diplomaType?.name ?? '-'}</td>

                                    <td className='text-center'>
                                        <button className='btn btn-light' onClick={() => handleDetail(formation.id)}>
                                            <img src={edit_img} alt='edit' />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Formations
