
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useTooltips from '../../hooks/useTooltips.js';

import StringUtil from "../../utils/StringUtil.js";
import CatalogLogic from '../../logic/CatalogLogic.js';
import CatalogSelector from '../selector/CatalogSelector.js';
import LevelSelector from '../selector/LevelSelector.js';

import './DeclarativeView.css';
import Tooltip from './Tooltip.js';

const DeclarativeViewSelectable = ({ companyCategory, surveyCategory, onChange }) => {

    const { t } = useTranslation();

    /**
     * Page state
     */

    const [sourceTree, setSourceTree] = useState({ children: [] });
    const [targetTree, setTargetTree] = useState({ children: [] });

    /**
     * Page lifecycle
    */

    useTooltips();

    useEffect(() => {

        (async () => {

            // Create a new company category tree
            const sourceTree = {
                id: 'root',
                label: t('surveys.availableskills'),
                state: { opened: true },
                children: companyCategory.children ?? []
            }

            // Create a new survey category tree
            const targetTree = {
                id: 'root',
                label: t('surveys.selectedskills'),
                state: { opened: true },
                children: surveyCategory.children ?? []
            }

            setSourceTree(sourceTree);
            setTargetTree(targetTree);

        })()

        // eslint-disable-next-line 
    }, [companyCategory, surveyCategory]);

    /**
     * Event handlers
     */

    const handleSourceChange = (sourceTree) => {
        setSourceTree(sourceTree);
    }

    const handleTargetChange = (targetTree) => {
        setTargetTree(targetTree);
        onChange({ ...surveyCategory, children: targetTree.children });
    }

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(targetTree, skill, level);

        // And propagates the category change to the owner
        onChange({ ...surveyCategory, children: targetTree.children });
    }

    return (

        <div className='container-fluid'>
            <div className='row'>

                {
                    !StringUtil.isEmpty(surveyCategory.description) &&
                    <div className='border rounded text-center mx-3 mb-3 p-3'>{surveyCategory.description}</div>
                }

                <CatalogSelector
                    source={sourceTree}
                    target={targetTree}
                    targetEditable='false'
                    onSourceChange={handleSourceChange}
                    onTargetChange={handleTargetChange}
                >
                    {
                        (skill) => {

                            const hasTooltip = !StringUtil.isEmpty(skill.description);
                            const showLevel  = CatalogLogic.hasLevel(skill);

                            return (
                                <tr>
                                    <td>
                                        {
                                            hasTooltip &&
                                            <Tooltip title={skill.description} content={skill.label} />
                                        }
                                        {
                                            !hasTooltip &&
                                            skill.label
                                        }
                                    </td>
                                    <td>
                                        {
                                            showLevel &&
                                            <LevelSelector levels={surveyCategory.levels} value={skill.level} onChange={(value) => handleLevelChange(skill, value)} />
                                        }

                                    </td>
                                </tr>
                            )
                        }
                    }
                </CatalogSelector>

            </div>
        </div>
    )
}

export default DeclarativeViewSelectable;
