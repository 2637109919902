
import { useState, useEffect } from 'react';

import InfoBox from '../../../components/charts/InfoBox.js';
import UserListSingleSelect from '../../../components/charts/UserListSingleSelect.js';
import UserBox from '../../../components/charts/UserBox.js';
import GapIndividualView from '../../../components/charts/GapIndividualView.js';

const TabDevAnalysis = ({ skilltree, users, onTreeChange }) => {

    /**
     * Page state
     */

    const [selectedUser, setSelectedUser] = useState();

    /**
     * Page lifecycle
     */

    useEffect(() => { 
        if (users && users.length > 0) setSelectedUser(users[0]);
    }, [users]);

    /**
     * Event handlers
     */

    const handleTreeChange = (skilltree) => {
        onTreeChange(skilltree);
    }

    const handleSelectionChange = (selectedUser) => {
        setSelectedUser(selectedUser);
    } 

    return (

        <div className='d-flex'>

            <div>
                <InfoBox users={users} />
                <UserListSingleSelect users={users} selectedUser={selectedUser} onSelectionChange={handleSelectionChange} />
            </div>

            <div className='flex-grow-1 mx-3 p-3 border rounded'>
                <GapIndividualView skilltree={skilltree} selectedUser={selectedUser} onTreeChange={handleTreeChange} />
            </div>

            <div>
                <UserBox user={selectedUser} />
            </div>

        </div>
    )
}

export default TabDevAnalysis;